import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import PrivacyPolicyArea from '../components/PrivacyPolicy/PrivacyPolicyArea'
import Footer from '../components/_App/Footer'

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Seo title='Privacy Policy' />

      <TopHeader />

      <Navbar />

      <PrivacyPolicyArea />

      <Footer />
    </Layout>
  )
}

export default PrivacyPolicyPage
